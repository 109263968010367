import { gql } from "apollo-boost";

const CREATE_VALID_DATE = gql`
  mutation createValidDate($input: validDateInput) {
    createValidDate(input: $input) {
      id
      valid_type
      valid_date
      updated_by {
        id
        email
        is_verified
        is_primary
        arranger {
          prefix
          first_name
          last_name
        }
      }
    }
  }
`;

const GET_VALID_DATE = gql`
  query ($valid_type: ValidDateTypeEnum!) {
    validDate(valid_type: $valid_type) {
      id
      valid_type
      valid_date
      updated_at
    }
  }
`;

export { CREATE_VALID_DATE, GET_VALID_DATE };
