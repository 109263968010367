import { gql } from "apollo-boost";

const GET_ARRANGERS = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangers(filter: $filter, page: $page, first: $first, orderBy: $orderBy) {
      data {
        id
        total_reservation
        last_booking_date
        last_signin_date
        class_type_id
        campaign_id
        company {
          id
          name
          arrangers {
            id
            company {
              id
              name
              class_type {
                id
                name
              }
            }
          }
        }
        prefix
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
        nationality {
          id
          name
        }
        dob
        passport
        passport_exp
        address
        township {
          id
          name
          city_id
          city {
            id
            name
            region_id
            region {
              id
              name
              country {
                id
                name
                iso_code_2
                iso_code_3
                currency_code
                nationality
                phone
              }
            }
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_ARRANGERS_LIST = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangers(filter: $filter, page: $page, first: $first, orderBy: $orderBy) {
      data {
        id
        total_reservation
        active
        total_completed_reservation
        last_booking_date
        last_signin_date
        class_type_id
        campaign_id
        company {
          id
          name
        }
        prefix
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
        nationality {
          id
          name
        }
        dob
        passport
        passport_exp
        # loyalty_programs {
        #   id
        #   arranger_id
        #   tier_id
        #   loyalty_tiers_v2 {
        #     id
        #     tier
        #     label
        #   }
        #   points
        #   tier_expiration_date
        #   total_amount_spend
        #   created_at
        #   updated_at
        #   deleted_at
        #   total_points
        # }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_ARRANGER = gql`
  mutation ($input: arrangerInput) {
    createArranger(input: $input) {
      id
      prefix
      first_name
      last_name
      class_type_id
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      dob
      nationality {
        id
      }
      passport
      passport_exp
      nrc
      address
      township {
        id
      }
    }
  }
`;

const GET_ARRANGER_BY_ID = gql`
  query ($id: ID!) {
    arranger(id: $id) {
      id
      company {
        id
        name
      }
      prefix
      first_name
      last_name
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
        verified_by
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        arranger_phone_verified_type {
          id
          name
        }
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        verified_by
        arranger_email_verified_type {
          id
          name
        }
      }
      nationality {
        id
        name
      }
      dob
      nrc
      passport
      passport_exp
      active
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
      total_reservation
      last_booking_date
      last_signin_date
      ccm_status {
        id
        name
      }
      campaign_id
      roles {
        id
        name
      }
      created_at
    }
  }
`;

const GET_ARRANGER_ACTVITY_LOGS = gql`
  query ($id: ID!) {
    arranger(id: $id) {
      id
      logs {
        id
        causer {
          id
          email
        }
        description
        updates {
          key
          old
          new
        }
        raw
        type
        created_at
      }
    }
  }
`;

const GET_ARRANGER_IDNAME_ONLY = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangers(filter: $filter, page: $page, first: $first, orderBy: $orderBy) {
      data {
        id
        prefix
        first_name
        last_name
      }
    }
  }
`;

const GET_PEOPLE_ROLE = gql`
  {
    peopleRole {
      id
      name
    }
  }
`;

const CREATE_ARRANGER_SNAPSHOT = gql`
  mutation ($input: arrangerSnapshotInput) {
    createArrangerSnapshot(input: $input) {
      id
      prefix
      first_name
      last_name
      phone_prefix
      phone
      email
      dob
      nationality {
        id
      }
      passport
      passport_exp
      nrc
      address
      township {
        id
      }
    }
  }
`;

const CREATE_ARRANGER_PHONE = gql`
  mutation createArrangerPhone($input: arrangerPhoneCreateInput) {
    createArrangerPhone(input: $input) {
      id
      phone_prefix
      phone
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const VERIFY_PHONE = gql`
  mutation ($input: VerifyArrangerPhoneCCMInput) {
    verifyArrangerPhoneCCM(input: $input) {
      id
      phone
      is_primary
      is_verified
      verified_by
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const CREATE_ARRANGER_EMAIL = gql`
  mutation createArrangerEmail($input: arrangerEmailCreateInput) {
    createArrangerEmail(input: $input) {
      id
      email
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const EDIT_ARRANGER_EMAIL = gql`
  mutation editArrangerEmail($input: arrangerEmailEditInput) {
    editArrangerEmail(input: $input) {
      id
      email
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;
const CREATE_ARRANGER_COMMENT = gql`
  mutation ($input: commentInput) {
    createComment(input: $input) {
      id
      subject_id
      subject_type
      comments
      deleted_at
      created_at
      updated_at
      employee {
        id
        email
      }
    }
  }
`;

const GET_ARRANGER_COMMENT = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    comments(page: $page, first: $first, filter: $filter, orderBy: $orderBy) {
      data {
        id
        subject_id
        subject_type
        comments
        deleted_at
        created_at
        updated_at
        employee {
          id
          email
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const DELETE_ARRANGER_COMMENT = gql`
  mutation ($includeIds: [Int!]!) {
    deleteComment(includeIds: $includeIds) {
      id
      comments
    }
  }
`;

const ADDRESSBOOK_BY_ARRANGER = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    addressBooksByArranger(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        arranger {
          id
          first_name
          last_name
        }
        deleted_at
        prefix
        first_name
        last_name
        phone
        email
        nationality {
          id
          name
          iso_code_2
          iso_code_3
          currency_code
          phone
        }
        dob
        passport
        passport_exp
        nrc
        address
        township {
          id
          name
          city {
            id
            name
            region {
              id
              name
            }
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const DELETE_ARRANGER_PHONE = gql`
  mutation deleteArrangerPhone($id: ID!) {
    deleteArrangerPhone(id: $id) {
      id
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const DELETE_ARRANGER_EMAIL = gql`
  mutation deleteArrangerEmail($id: ID!) {
    deleteArrangerEmail(id: $id) {
      id
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const UNVERIFY_PHONE = gql`
  mutation ($input: VerifyArrangerPhoneCCMInput) {
    unverifyArrangerPhoneCCM(input: $input) {
      id
      phone
      is_primary
      is_verified
      verified_by
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const UNVERIFY_ARRANGER_EMAIL = gql`
  mutation ($input: VerifyEmailCCMInput!) {
    unverifyArrangerEmailCCM(input: $input) {
      id
      email
      is_primary
      is_verified
      verified_by
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const VERIFY_ARRANGER_EMAIL = gql`
  mutation ($input: VerifyEmailCCMInput!) {
    verifyArrangerEmailCCM(input: $input) {
      id
      email
      is_primary
      is_verified
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const MAKE_PRIMARY_ARRANGER_PHONE = gql`
  mutation ($input: makePrimaryArrangerPhoneInput!) {
    makePrimaryArrangerPhone(input: $input) {
      id
      arranger {
        id
        first_name
        last_name
        arranger_phones {
          id
          phone_prefix
          phone
          is_verified
          is_primary
        }
      }
    }
  }
`;

const MAKE_PRIMARY_ARRANGER_EMAIL = gql`
  mutation ($input: makePrimaryArrangerEmailInput!) {
    makePrimaryArrangerEmail(input: $input) {
      id
      arranger {
        id
        first_name
        last_name
        arranger_emails {
          id
          email
          is_verified
          is_primary
        }
      }
    }
  }
`;

const MERGE_ARRANGER = gql`
  mutation ($input: mergeArrangerInput) {
    mergeArranger(input: $input) {
      AddressBook
      ArrangerArrangerReferralProgram
      ArrangerEmail
      ArrangerPhone
      ArrangerSnapshot
      ArrangerViber
      LoginHistory
      User
      LoyaltyProgram
      Passenger
      WalletActivityLog
      Reservation
      TrackSms
    }
  }
`;

const ARRANGERS_WITH_FLAG = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    arrangersWithFlag(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        prefix
        first_name
        last_name
        total_reservation
        nationality {
          id
          name
        }
        dob
        nrc
        passport
        passport_exp
        created_at
        address
        township {
          id
          name
          city_id
          city {
            id
            name
            region_id
            region {
              id
              name
              country {
                id
                name
                iso_code_2
                iso_code_3
                currency_code
                nationality
                phone
              }
            }
          }
        }
        company {
          id
          name
        }
        arranger_emails {
          id
          email
          is_verified
          is_primary
          duplicated_arranger_id
          arranger_email_verified_type {
            id
            name
          }
        }
        arranger_phones {
          id
          phone
          is_verified
          is_primary
          phone_prefix
          duplicated_arranger_id
          arranger_phone_verified_type {
            id
            name
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        perPage
      }
    }
  }
`;

const ARRANGER_ID_MERGE = gql`
  mutation ($input: mergeArrangerInput) {
    mergeArranger(input: $input) {
      message
    }
  }
`;

const GET_MAIN_ARRANGER = gql`
  query ($id: ID!) {
    arranger(id: $id) {
      id
      company {
        id
        name
      }
      prefix
      first_name
      last_name
      created_at
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      nationality {
        id
        name
      }
      dob
      nrc
      passport
      passport_exp
      created_at
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
      roles {
        id
        name
      }
    }
  }
`;

const GET_ARRANGERS_BY_EMAIL = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangersByEmail(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      id
      total_reservation
      last_booking_date
      last_signin_date
      company {
        id
        name
        arrangers {
          id
          company {
            id
            name
            class_type {
              id
              name
            }
          }
        }
      }
      prefix
      first_name
      last_name
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
    }
  }
`;

const GET_ARRANGERS_BY_PHONE = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangersByPhone(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      id
      total_reservation
      last_booking_date
      last_signin_date
      company {
        id
        name
        arrangers {
          id
          company {
            id
            name
            class_type {
              id
              name
            }
          }
        }
      }
      prefix
      first_name
      last_name
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
    }
  }
`;

const PEOPLE_REFERRAL_PROGRAMS = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangerArrangerReferralProgram(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      data {
        id
        code
        active
        used_time
        net_used_time
        arranger_referral_program {
          id
          name
          status
        }
        arranger {
          id
          first_name
          last_name
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const POINT_CALCULATION = gql`
  mutation ($arranger_id: Int!) {
    calculate_points(arranger_id: $arranger_id) {
      id
      loyalty_tier {
        id
        tier
        label
      }
      points
    }
  }
`;
const GET_CCM_STATUS = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    ccm_statuses(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        name
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_ARRANGERS_BY_UNVERIFY_PHONE = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangersByUnverifiedPhone(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      id
      total_reservation
      active
      total_completed_reservation
      last_booking_date
      last_signin_date
      company {
        id
        name
        arrangers {
          id
          company {
            id
            name
            class_type {
              id
              name
            }
          }
        }
      }
      prefix
      first_name
      last_name
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
    }
  }
`;

const GET_ARRANGERS_BY_UNVERIFY_EMAIL = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    arrangersByUnverifiedEmail(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      id
      total_reservation
      active
      total_completed_reservation
      last_booking_date
      last_signin_date
      company {
        id
        name
        arrangers {
          id
          company {
            id
            name
            class_type {
              id
              name
            }
          }
        }
      }
      prefix
      first_name
      last_name
      arranger_phones {
        id
        phone_prefix
        phone
        is_verified
        is_primary
      }
      arranger_emails {
        id
        email
        is_verified
        is_primary
      }
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
    }
  }
`;

const RESEND_VERIFY_EMAIL = gql`
  mutation ($input: ReSendVerifyEmailInput!) {
    reSendVerifyEmail(input: $input) {
      status
      message
    }
  }
`;

const GET_SESSION_HISTORY = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    flymyaSessions(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        session_id
        arranger_id
        device_id
        client_platform_id
        expired_at
        metadata
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_FLYMYA_SESSIONS_BY_DEVICE_ID = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    flymyaSessionsByDeviceId(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      id
      session_id
      arranger_id
      device_id
      client_platform_id
      expired_at
      metadata
    }
  }
`;

const GET_ARRANGER_EMAIL = gql`
  query ($email: Email!) {
    arrangerEmail(email: $email) {
      id
      email
      is_primary
      is_verified
    }
  }
`;

const GET_TOTAL_DUP_ARR_EMAIL = gql`
  {
    totalDuplicatedArrangerEmails {
      total_duplicated_emails
    }
  }
`;

const GET_TOTAL_DUP_ARR_PHONE = gql`
  {
    totalDuplicatedArrangerPhones {
      total_duplicated_phones
    }
  }
`;

export {
  GET_ARRANGERS,
  GET_ARRANGERS_LIST,
  CREATE_ARRANGER,
  GET_ARRANGER_BY_ID,
  GET_ARRANGER_ACTVITY_LOGS,
  GET_ARRANGER_IDNAME_ONLY,
  GET_PEOPLE_ROLE,
  GET_ARRANGER_COMMENT,
  CREATE_ARRANGER_SNAPSHOT,
  CREATE_ARRANGER_PHONE,
  VERIFY_PHONE,
  CREATE_ARRANGER_EMAIL,
  EDIT_ARRANGER_EMAIL,
  CREATE_ARRANGER_COMMENT,
  DELETE_ARRANGER_COMMENT,
  ADDRESSBOOK_BY_ARRANGER,
  DELETE_ARRANGER_PHONE,
  DELETE_ARRANGER_EMAIL,
  UNVERIFY_PHONE,
  UNVERIFY_ARRANGER_EMAIL,
  VERIFY_ARRANGER_EMAIL,
  MAKE_PRIMARY_ARRANGER_PHONE,
  MAKE_PRIMARY_ARRANGER_EMAIL,
  MERGE_ARRANGER,
  ARRANGERS_WITH_FLAG,
  ARRANGER_ID_MERGE,
  GET_MAIN_ARRANGER,
  GET_ARRANGERS_BY_EMAIL,
  GET_ARRANGERS_BY_PHONE,
  PEOPLE_REFERRAL_PROGRAMS,
  POINT_CALCULATION,
  GET_ARRANGERS_BY_UNVERIFY_PHONE,
  GET_ARRANGERS_BY_UNVERIFY_EMAIL,
  RESEND_VERIFY_EMAIL,
  GET_CCM_STATUS,
  GET_SESSION_HISTORY,
  GET_FLYMYA_SESSIONS_BY_DEVICE_ID,
  GET_ARRANGER_EMAIL,
  GET_TOTAL_DUP_ARR_EMAIL,
  GET_TOTAL_DUP_ARR_PHONE,
};
